import { hydrateWhenVisible } from 'vue-lazy-hydration'
import AppLink from '@theme/components/atom/AppLink'
import CartInfoDropDownContent from '@theme/components/cart/CartInfoDropDownContent'
import HeaderMain from '~/themes/base/components/shop/HeaderMain'
import HeaderTopLogin from '@theme/components/shop/HeaderTopLogin'
import IconLink from '@theme/components/molecule/IconLink'
import InfoCountMixin from '@/mixins/InfoCountMixin'
import LoginModalMixin from '@/mixins/LoginModalMixin'
import WishListInfo from '@theme/components/user/WishListInfo'

export default {
  extends: HeaderMain,
  components: {
    AppLink,
    CartInfoDropDownContent,
    CurrencySwitcher: hydrateWhenVisible(() => import('@theme/components/shop/CurrencySwitcher')),
    DropdownLinks: () => import('@theme/components/utils/DropdownLinks'),
    HeaderMain,
    HeaderTopLogin,
    IconLink,
    LanguageSwitcher: () => import('@theme/components/shop/LanguageSwitcher'),
    LoginModal: () => import('@theme/components/user/LoginModal'),
    LogoutButton: () => import('@theme/components/user/LogoutButton'),
    WishListInfo,
  },
  mixins: [InfoCountMixin, LoginModalMixin],
  data() {
    return {
      settings: {
        userMenu: {
          login: true,
          logout: true,
          register: false,
        },
      },
      userMenuIcons: {
        login: require('@icon/represhop/person.svg?raw'),
        logout: require('@icon/represhop/person.svg?raw'),
        register: require('@icon/represhop/person.svg?raw'),
      },
    }
  },
  computed: {
    userMenu() {
      return this.$store.state.globals.userMenu
    },
    userShortcut() {
      let shortcut = ''
      const { firstName, lastName, name } = this.$store.state.user.userData || {}
      if (firstName && lastName) {
        shortcut = `${firstName.slice(0, 1)}${lastName.slice(0, 1)}`
      } else {
        const dividedName = name.split(' ')
        if (dividedName.length > 1) {
          shortcut = `${dividedName[0].slice(0, 1)}${dividedName[1].slice(0, 1)}`
        } else {
          shortcut = dividedName[0].slice(0, 2)
        }
      }
      return shortcut
    },
  },
  methods: {
    handleLoginLinkClick(e) {
      if (this.$themeSettings.components.HeaderMain.loginLink.type !== 'link') {
        e.preventDefault()
        if (this.$themeSettings.components.HeaderMain.loginLink.type === 'modal') {
          this.openLoginModal(e)
        }
      }
    },
    toggleDesktopSearch(e) {
      e.preventDefault()
    },
  },
}

import CartInfo from '~/themes/base/components/cart/CartInfo'

export default {
  extends: CartInfo,
  data() {
    return {
      wishListForceDisable: true,
    }
  },
}

import { get } from 'lodash'
import AppImage from '@theme/components/atom/AppImage'
import CheckoutFreeDelivery from '@theme/components/cart/CheckoutFreeDelivery'
import CheckoutFreeGift from '@theme/components/cart/CheckoutFreeGift'
import CheckoutItemLine from '@theme/components/cart/CheckoutItemLine'
import DeliveryDate from '@theme/components/shop/DeliveryDate'
import PricesMixin from '~/mixins/PricesMixin'
import ProductLinkMixin from '~/mixins/ProductLinkMixin'
import RemoveFromCart from '@theme/components/cart/RemoveFromCart'

export default {
  components: {
    AppImage,
    CheckoutFreeDelivery,
    CheckoutFreeGift,
    CheckoutItemLine,
    DeliveryDate,
    RemoveFromCart,
  },
  mixins: [PricesMixin, ProductLinkMixin],
  data() {
    return {
      cartItemAmount: null,
    }
  },
  mounted() {
    if (this.settings.showCartItemAmount) {
      this.loadCartItemAmount()
    }
  },
  computed: {
    itemsCount: function() {
      const cartItems = this.$store.state.cart.cartData.items
      if (cartItems) {
        return this.$store.state.cart.cartData.items.reduce((acc, item) => acc + item.amount, 0)
      } else {
        return 0
      }
    },
    settings() {
      return this.$themeSettings.components.CartInfoDropDownContent
    },
  },
  methods: {
    getItemPrice(item) {
      const { amount, prices } = item || {}
      return this.getPrimaryPrice({ prices, amount })
    },
    getSummaryTotalPrice() {
      const { decimal, show, withTax } = this.getPriceSettings('summaryTotalPrimary')
      const prices = this.$store.state.cart.cartData.prices || {}
      const paymentPrice = get(prices, withTax ? 'paymentPriceWithTax' : 'paymentPriceWithoutTax', 0)
      const shipperPrice = get(prices, withTax ? 'shipperPriceWithTax' : 'shipperPriceWithoutTax', 0)
      const totalPrice = this.getNonformattedPrice({
        prices,
        type: 'summaryTotalPrimary',
        ...this.defaultTotalPriceKeys,
      })
      const finalPrice = totalPrice - (paymentPrice || 0) - (shipperPrice || 0)
      return show && this.$currencyFormat(finalPrice, { decimalPlaces: decimal || 0 })
    },
    async loadCartItemAmount() {
      this.cartItemAmount = (await import('@theme/components/cart/CartItemAmount')).default
    },
  },
}
